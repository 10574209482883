.login {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100dvh;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;

  font-family: Nunito;
}

.loginPage_BG {
  position: absolute;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 70vw;
  object-fit: cover;
}

.form_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100dvh;
}

.logo {
  width: 40%;
}

.logo img {
  max-height: 320px;
}

.form_section {
  width: 60%;
  height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #fff2ec;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  overflow: hidden;
  /* max-width: 400px; */
}

.form {
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.form_title {
  font-size: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 600px) {
  .form_title {
    font-size: 20px;
    font-weight: 600;
  }
}

.form_title img {
  cursor: pointer;
}

.form_components {
  display: flex;
  flex-direction: column;
}

.form_component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin: 10px 0; */
  gap: 10px;
  margin-bottom: 20px;
}

.form_component .title {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  color: #515357;
}

.form_component .input_container {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  align-items: center;

  border: 1px solid #000000;
}

.form_component .input_container input {
  width: 100%;
  min-width: 300px;
  height: 100%;
  border: none;
  outline: none;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  background-color: #ffffff;
  border-radius: 6px;

  padding-left: 60px;
  /* text-align: center; */
}

@media screen and (max-width: 1200px) {
  .form_component .input_container input {
    min-width: 200px;
  }
}

.form_component .input_container input.password_input {
  padding-right: 60px;
}

.input_container img {
  width: 30px;
  height: 31px;
  padding: 10px;
  position: absolute;

  /* border-right: #000000 1px solid; */
  border-left: #000000 1px solid;
  right: 0px;
}

.input_container img:nth-child(1) {
  border-left: none;
  border-right: #000000 1px solid;
  left: 0px;
}

.password_reveal {
  cursor: pointer;
}

.submit_button {
  width: 100%;
  height: 50px;
  margin: 20px 0;
  border: none;
  border-radius: 6px;
  background-color: #fa7b42;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.error_msg {
  color: #e80000;
  font-size: 16px;
  font-weight: 500;

  margin-top: -10px;
  margin-bottom: -10px;

  max-width: 350px;
  align-self: center;
}

.additional_info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.additional_info > div,
span {
  color: #696f79;
}

.highlight {
  color: #fa7b42;
  cursor: pointer;
}

/**Otp Authentication**/

.otp_container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.otp_container input {
  width: 40px;
  height: 40px;
  border: 1px solid #000000;
  border-radius: 6px;
  outline: none;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.otp_container input::-webkit-outer-spin-button,
.otp_container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otp_container input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 1000px) {
  .login {
    justify-content: center;
  }

  .loginPage_BG {
    display: none;
  }

  .form_container {
    width: 100%;
    height: 100%;
  }

  .logo {
    display: none;
  }

  .form_section {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.footer p,
a {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #696f79;
}
